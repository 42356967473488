<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close" @closed="closed" >
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="姓名" prop="username">
						<el-input v-model="form.username"/>
					</el-form-item>
					<el-form-item label="头衔" prop="position">
						<el-input type="textarea" v-model="form.position"/>
					</el-form-item>
					<el-form-item label="头像" prop="avatar">
						<form-upload v-model="form.avatar"/>
					</el-form-item>
					<el-form-item label="权重" prop="sort">
						<el-input type="number" v-model="form.sort"/>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {speaker as api} from "@/api/admin";
import _ from "lodash";

export default {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
		}
	},
	computed:{
		rules(){
			return {
				username:[
					{required:true,message:"请输入姓名"}
				],
				position:[
					{required:true,message:"请输入头衔"}
				],
				avatar:[
					{required:true,message:"请上传头像"}
				]
			}
		}
	},
	methods:{
		getDetail(){
			api.get({
				id:this.query.id
			}).then((res)=>{
				const data = res.detail;
				this.form = this.decodeData(data);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data = this.encodeData(data);

				this.submit_loading = true;
				api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		decodeData(data){
			data.status = data.status === 1;
			return data;
		},
		encodeData(data){
			data.status = data.status ? 1 : 2;
			return data;
		},
		init(query = {}){
			this.query = query;
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
			this.visible = true;
		},
		close(){
			this.visible = false;
		},
		closed(){
			this.form = {};
			this.$refs['formRef'].resetFields();
		}
	}
}
</script>
